<template>
    <!-- begin:: Aside -->
    <div class="aside aside-left d-flex aside-fixed" id="kt_aside" ref="kt_aside">
        <!--begin::Primary-->
        <div class="aside-primary d-flex flex-column align-items-center flex-row-auto">
            <!--begin::Brand-->
            <KTBrand></KTBrand>
            <!--end::Brand-->
            <!--begin::Nav Wrapper-->
            <div
                class="aside-nav d-flex flex-column align-items-center flex-column-fluid py-5 scroll scroll-pull ps"
                style="overflow: hidden"
            >
                <!--begin::Nav-->
                <ul class="nav flex-column" role="tablist">
                    <!--begin::Item-->
                    <li
                        class="nav-item mb-3"
                        data-placement="right"
                        data-container="body"
                        data-boundary="window"
                        v-b-tooltip.hover.right="'Mes classes'"
                        hidden
                    >
                        <a href="#" class="nav-link btn btn-icon btn-clean btn-lg active" role="tab" v-on:click="setActiveTab" data-tab="4">
                            <span class="svg-icon svg-icon-xl">
                                <!--begin::Svg Icon-->
                                <inline-svg src="media/svg/icons/Home/Library.svg" />
                                <!--end::Svg Icon-->
                            </span>
                        </a>
                    </li>
                    <KTQuickUser />
                    <li
                        class="nav-item mb-2 btn btn-clean py-2 px-1 w-100 item-menu"
                        data-placement="right"
                        data-container="body"
                        data-boundary="window"
                    >
                        <router-link :to="{ name: 'dashboard' }" replace>
                            <div
                                class="nav-link m-0 my-1 p-0 d-flex flex-column align-items-center"
                                role="tab"
                                data-tab="1"
                                v-on:click="setActiveTab"
                            >
                                <span class="svg-icon svg-icon-xl m-0" :class="`${tabIndex === 1 ? 'svg-icon-primary' : ''}`">
                                    <inline-svg src="/media/svg/icons/Home/Book.svg" />
                                </span>
                                <p class="m-0 p-0">Mes Classes</p>
                            </div>
                        </router-link>
                    </li>
                    <li
                        v-if="displayCatalogVar"
                        class="nav-item mb-2 btn btn-clean py-2 px-1 w-100 item-menu"
                        data-placement="right"
                        data-container="body"
                        data-boundary="window"
                    >
                        <router-link :to="{ name: 'catalogue', query: { activeTable: 'catalogue' } }" replace>
                            <div
                                class="nav-link m-0 my-1 p-0 d-flex flex-column align-items-center"
                                role="tab"
                                data-tab="2"
                                v-on:click="setActiveTab"
                            >
                                <span class="svg-icon svg-icon-xl m-0" :class="`${tabIndex === 2 ? 'svg-icon-primary' : ''}`">
                                    <inline-svg src="/media/svg/icons/Home/Book-open.svg" />
                                </span>
                                <p class="m-0 p-0">Catalogue</p>
                            </div>
                        </router-link>
                    </li>
                    <li
                        class="nav-item mb-2 btn btn-clean py-2 px-1 w-100 item-menu"
                        data-placement="right"
                        data-container="body"
                        data-boundary="window"
                        v-if="
                            this.$store.getters.currentUser.role == 'admin' ||
                            this.checkRights([1]) === true ||
                            this.checkRights([2]) === true
                        "
                    >
                        <router-link :to="{ name: 'classrooms' }" replace>
                            <div
                                class="nav-link m-0 my-1 p-0 d-flex flex-column align-items-center"
                                role="tab"
                                data-tab="3"
                                v-on:click="setActiveTab"
                            >
                                <span class="svg-icon svg-icon-xl m-0" :class="`${tabIndex === 3 ? 'svg-icon-primary' : ''}`">
                                    <inline-svg src="/media/svg/icons/classrooms.svg" />
                                </span>
                                <p class="m-0 p-0">Classerooms</p>
                            </div>
                        </router-link>
                    </li>
                    <!--end::Item-->

                    <li
                        class="nav-item mb-2 btn btn-clean py-2 px-1 w-100 item-menu"
                        data-placement="right"
                        data-container="body"
                        data-boundary="window"
                    >
                        <a :href="plateformURL" replace>
                            <div
                                class="nav-link m-0 my-1 p-0 d-flex flex-column align-items-center"
                                role="tab"
                                data-tab="4"
                                v-on:click="setActiveTab"
                            >
                                <span class="svg-icon svg-icon-xl m-0" :class="`${tabIndex === 4 ? 'svg-icon-primary' : ''}`">
                                    <inline-svg src="/media/svg/logos/si2p.svg" />
                                </span>
                                <p class="m-0 p-0">Plateforme</p>
                            </div>
                        </a>
                    </li>

                    <li
                        class="nav-item mb-2 btn btn-clean py-2 px-1 w-100 item-menu"
                        data-placement="right"
                        data-container="body"
                        data-boundary="window"
                    >
                        <router-link
                            v-if="
                                $router.currentRoute.matched[0]['path'] !== '/admin' &&
                                ($store.getters.currentUser.role === 'admin' || $store.getters.currentUser.role === 'gestionnaire')
                            "
                            :to="{ name: 'admin/dashboard', query: { activeTable: 'classes' } }"
                            replace
                        >
                            <div
                                class="nav-link m-0 my-1 p-0 d-flex flex-column align-items-center"
                                role="tab"
                                data-tab="5"
                                v-on:click="setActiveTab"
                            >
                                <span class="svg-icon svg-icon-xl m-0" :class="`${tabIndex === 5 ? 'svg-icon-primary' : ''}`">
                                    <inline-svg src="/media/svg/icons/Tools/Tools.svg" />
                                </span>
                                <p class="m-0 p-0">Backoffice</p>
                            </div>
                        </router-link>
                    </li>
                </ul>
                <!--end::Nav-->
            </div>
            <!--end::Nav Wrapper-->
            <!--begin::Footer-->

            <!--end::Footer-->
        </div>
        <!--end::Primary-->
        <!--begin::Secondary-->
    </div>
    <!-- end:: Aside -->
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
    display: none;
}
</style>

<script>
import { mapGetters } from 'vuex';
import KTLayoutAside from '@/assets/js/layout/base/aside.js';
import KTLayoutAsideMenu from '@/assets/js/layout/base/aside-menu.js';
import KTLayoutAsideToggle from '@/assets/js/layout/base/aside-toggle.js';
import KTBrand from '@/view/layout/brand/Brand.vue';
import KTQuickUser from '@/view/layout/extras/offcanvas/QuickUser.vue';

export default {
    name: 'KTAside',
    data() {
        return {
            insideTm: 0,
            outsideTm: 0,
            tabIndex: 0,
            plateformURL: process.env.VUE_APP_PLATEFORME_URL,
            demo: false,
            displayCatalogVar: true,
            navItems: [
                { name: 'classes', index: 1 },
                { name: 'catalogue', index: 2 },
                { name: 'plateforme', index: 3 },
                { name: 'backoffice', index: 4 }
            ]
        };
    },
    components: {
        KTBrand,
        KTQuickUser
    },
    mounted() {
        this.$nextTick(() => {
            // Init Aside
            KTLayoutAside.init(this.$refs['kt_aside']);

            // Init Aside Menu
            KTLayoutAsideMenu.init(this.$refs['kt_aside_menu']);

            // Init Aside Toggle
            KTLayoutAsideToggle.init(this.$refs['kt_aside_toggle']);

            //check if demo
            this.isDemo();
            this.displayCatalog();

            this.$route.query.activeTable
                ? this.navItems.find((el) => el.name === this.$route.query.activeTable)
                    ? (this.tabIndex = this.navItems.find((el) => el.name === this.$route.query.activeTable).index)
                    : null
                : null;
        });
    },
    methods: {
        setActiveTab(event) {
            let target = event.target;
            if (!event.target.classList.contains('nav-link')) {
                target = event.target.closest('.nav-link');
            }

            const tab = target.closest('[role="tablist"]');
            const links = tab.querySelectorAll('.nav-link');
            // remove active tab links
            for (let i = 0; i < links.length; i++) {
                links[i].classList.remove('active');
            }

            // set clicked tab index to bootstrap tab
            this.tabIndex = parseInt(target.getAttribute('data-tab'));

            // set current active tab
            target.classList.add('active');
        },
        isDemo() {
            if (this.$store.getters.currentUser.demo === true) {
                this.demo = true;
            }
        },
        displayCatalog() {
            if (
                this.demo ||
                (this.$store.getters.currentUser.hideCatalog === 1 && this.$store.getters.currentUser.role === 'utilisateur') ||
                this.$store.getters.currentUser.role === 'admin'
            ) {
                this.displayCatalogVar = false;
            }
        },
        checkRights(rightIds) {
            let validation = false;
            if (
                this.$store.getters.currentUser &&
                this.$store.getters.currentUser.rights &&
                this.$store.getters.currentUser.rights.academy
            ) {
                this.$store.getters.currentUser.rights.academy.forEach((right) => {
                    if (rightIds.includes(right.right_id)) {
                        validation = true;
                    }
                });
                return validation;
            }
        }
    },
    computed: {
        ...mapGetters(['layoutConfig', 'getClasses']),

        /**
         * Get extra classes for menu based on the options
         */
        asideMenuClass() {
            const classes = this.getClasses('aside_menu');
            if (typeof classes !== 'undefined') {
                return classes.join(' ');
            }
            return null;
        }
    }
};
</script>
