<template>
    <div id="kt_header_mobile" class="header-mobile align-items-center" v-bind:class="headerClasses">
        <!--begin::Logo-->
        <button class="ml-3 btn p-0 burger-icon burger-icon-right" id="kt_aside_mobile_toggle">
            <span></span>
        </button>
        
        <h2 class="m-0 p-0" style="font-size: 1.2rem;">
            Academy - {{ $route.matched.some(({ name }) => name === 'admin') ? 'Backoffice' : 'Le service LMS de Si2P' }}
        </h2>
        <!-- <a href="/">
            <img alt="Logo" :src="headerLogo" class="logo-default max-h-50px" />
        </a> -->
        <!--end::Logo-->
        <!--begin::Toolbar-->
        <div class="d-flex align-items-center">
            <!--begin::Item-->
            <!--end::Item-->

            <!--end: User Bar -->
            
        </div>
        <!--end::Toolbar-->
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'KTHeaderMobile',
    components: {
    },
    mounted() {},
    data: () => ({
        display: window.screen.availWidth
    }),
    computed: {
        ...mapGetters(['layoutConfig', 'getClasses']),

        /**
         * Get header logo
         * @returns {string}
         */
        headerLogo() {
            return process.env.BASE_URL + this.layoutConfig('self.logo');
        },

        /**
         * Get classes for mobile header
         * @returns {null|*}
         */
        headerClasses() {
            const classes = this.getClasses('header_mobile');
            if (typeof classes !== 'undefined') {
                return classes.join(' ');
            }
            return null;
        },

        /**
         * Check if the left aside menu is enabled
         * @returns {boolean}
         */
        asideEnabled() {
            return !!this.layoutConfig('aside.self.display');
        }
    }
};
</script>
