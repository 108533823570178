<template>
    <!-- begin:: Header Topbar -->
    <div class="topbar d-flex flex-row justify-content-md-between align-items-center" style="width:100%;padding: 2vh 3em">
        <div id="subNav" class="d-flex flex-row justify-content-between align-items-center" style="width: inherit;">
            <img src="/media/logos/logo-si2p.png" style="max-height: 10vh; width: auto" alt="Logo du réseau Si2p" />
            <h1 class="m-0 p-0">
                Academy - {{ $route.matched.some(({ name }) => name === 'admin') ? 'Backoffice' : 'Le service LMS de Si2P' }}
            </h1>
            <span></span>
        </div>

        <div class="d-flex flex-row align-items-center">
            <!--begin::Item-->
            
            <!--end:Item-->
        </div>
    </div>
    <!-- end:: Header Topbar -->
</template>

<style lang="scss">
.topbar {
    max-height: 10vh;

    .dropdown-toggle {
        padding: 0;
        &:hover {
            text-decoration: none;
        }

        &.dropdown-toggle-no-caret {
            &:after {
                content: none;
            }
        }
    }

    .dropdown-menu {
        margin: 0;
        padding: 0;
        outline: none;
        .b-dropdown-text {
            padding: 0;
        }
    }
}
</style>

<script>
export default {
    name: 'AdminTopBar',
    components: {
    },
    data: () => ({
        activeTable: '',
        tabs: [
            { id: 'classes', name: 'classes', href: '?activeTable=classes' },
            { id: 'stagiaires', name: 'stagiaires', href: '?activeTable=stagiaires' },
            { id: 'clients', name: 'clients', href: '?activeTable=clients' }
        ],
        plateformURL: process.env.VUE_APP_PLATEFORME_URL
    }),
    mounted() {
        this.setActivePreviousTab();
    },
    methods: {
        setActivePreviousTab() {
            let table;
            if (this.$route.query.activeTable) {
                table = this.$route.query.activeTable;
            } else {
                table = 'classes';
            }
            this.activeTable = '?activeTable=' + table;
            localStorage.removeItem('activeTable');
            localStorage.setItem('activeTable', this.activeTable);
        }
    },
    computed: {}
};
</script>
